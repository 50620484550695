/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-11",
    versionChannel: "nightly",
    buildDate: "2024-09-11T00:06:20.152Z",
    commitHash: "aded7906728a83f54d29f9d4c0957b6d2e111158",
};
